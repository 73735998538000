@import 'styles/default.scss';

.title {
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: $fontMain;
}

.empty {
  width: 100%;
  padding-top: 30px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: $siteWidthMobile) {
  .title {
    font-size: 32px;
    margin-bottom: 18px;
  }
}
