@import 'styles/default.scss';
@import 'styles/colors.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: $borderRadius;
  color: #fff;
  padding: 14px;
  line-height: 1;
  min-width: 130px;

  &__dark {
    &.default {
      color: $darkBg;
      background-color: $darkTextColor;
    }

    &.link {
      color: $darkTextColor;

      .icon {
        color: $darkTextColor;
      }
    }

    &.transparent {
      border: 1px solid $darkTextColor;
      color: $darkTextColor;

      &:hover {
        background-color: $darkTextColor;
        color: $darkBg;

        .icon {
          color: $darkBg;
        }
      }

      .icon {
        color: $darkTextColor;
      }
    }

    .icon {
      color: $darkBg;
    }
  }
}

.small {
  font-size: 14px;
}

.middle {
  font-size: 16px;
}

.large {
  font-size: 18px;
}

.default {
  background-color: $defaultButtonBg;
}

.link {
  background: none;
  color: $linkColor;
  padding: 0;
  min-width: unset;
  line-height: 20px;
  text-align: left;
}

.link:hover {
  text-decoration: underline;
}

.disabled,
.disabled:hover {
  opacity: 0.5;
  cursor: default;
}

.transparent {
  background: unset;
  border: 1px solid $defaultButtonBg;
  color: $mainText;

  &:hover {
    background-color: $defaultButtonBg;
    color: #fff;
  }
}

.icon {
  height: 15px;
  margin-right: 8px;
}
