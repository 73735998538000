@import 'styles/colors.scss';

.root {
  display: block;
  margin-bottom: 25px;
  user-select: none;

  &__dark {
    color: $darkTextColor;

    .item {
      color: $darkTextColor;
    }
  }
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: nowrap;
  line-height: 1.8;
}

.item {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
}

.separator {
  margin: 0 5px;
}
