@import 'styles/default.scss';

.root {
  display: block;
  margin-top: 100px;
}

/*----------------------------------*/
/*----------- PIANO --------------  */

.keyboard-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  box-sizing: border-box;
  box-shadow: 1px 1px 30px 15px rgb(255 255 255 / 65%);

  &:hover .key-element {
    font-size: 28px;
  }
}

.key-element {
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0 0 10px 10px;
  transition: 0.1s;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
  font-size: 0;
  font-weight: bold;
  user-select: none;

  &_white {
    background-color: #fff;
    width: 100%;
    height: 400px;
    max-height: 95vh;
    box-shadow: 0 2px 3px 1px rgb(0 0 0 / 20%);
    border: 3px outset rgba(0, 0, 0, 0.2);
    border-top: 2px solid #bbb;
    color: #333;
  }

  &_black {
    background-color: #000;
    height: 200px;
    position: absolute;
    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 20%);
    border: 5px outset rgba(255, 255, 255, 0.3);
    border-top: unset;
    color: #ccc;
  }

  &_pressed {
    transition: 0.2s;
    box-shadow: unset;
    font-size: 28px;
  }
}

.accord-panel {
  margin-top: 40px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.accord-item {
  font-size: 20px;
  padding: 5px 20px;
  border: unset;
  border-radius: $borderRadius;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: 0.1s;
  box-sizing: border-box;
  background-color: wheat;
  margin-right: 20px;

  &:active {
    box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.15);
    transition: 0.1s;
  }
}
