@import 'styles/colors.scss';
@import 'styles/default.scss';

.root {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  touch-action: none;

  &__dark {
    .menuWrapper {
      background: $darkBg;
      @include themeSwitchTransition;

      .menuItem {
        color: #fff;
      }
    }

    .burgerMenu {
      border-color: $darkBgContent;

      &_line {
        background-color: #fff;
      }
    }

    .closeMenu::after,
    .closeMenu::before {
      background: #fff;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.menuWrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  transition: 0.4s;
  box-sizing: border-box;
}

.menuitems {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0;
}

.menuItem {
  color: $mainText;
  text-decoration: none;
  font-size: 20px;
  padding: 16px 0;
  width: 100%;
  box-sizing: border-box;
}

.toggle {
  padding: 16px 0;
}

.closeMenu {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 15;
}

.closeMenu::after,
.closeMenu::before {
  content: '';
  position: absolute;
  width: 80%;
  height: 3px;
  background: $mainText;
  top: 50%;
  left: 50%;
  transition: 0.25s;
}

.closeMenu::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.closeMenu::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.burgerMenu {
  display: flex;
  width: 44px;
  height: 36px;
  position: fixed;
  right: 16px;
  top: 22px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  z-index: 20;
  cursor: pointer;
  transition: 0.3s;
  padding: 9px 8px;

  &_line {
    width: 100%;
    height: 2px;
    background-color: rgba($color: $mainText, $alpha: 0.95);
  }
}

.sheetNav {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.navLanguageToggle {
  border: 1px solid $defaultBg;
}

@media screen and (max-width: $siteWidthMobile) {
  .root {
    display: block;
  }
}
