@import 'styles/default.scss';
@import 'styles/colors.scss';

$sliderThick: 4px;
$sliderColor: rgb(17, 36, 119);
$baseColor: rgb(217, 226, 255);

.root {
  display: block;
  margin: 0 auto;

  &__dark {
    .tabs {
      &__item {
        color: $darkTextColor;
        &_active {
          border-color: $darkTextColor;
        }
      }
    }
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 15px;

  &__item {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.8;
    
    &_active {
      opacity: 1;
      cursor: default;
      border-bottom: 4px solid $defaultBg;
    }
  }
}

.baseline {
  width: 100%;
  height: 1px;
  box-sizing: border-box;
  background: $baseColor;
  position: relative;
}

@media screen and (max-width: $siteWidthMobile) {
  .tabs {
    &__item {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: $siteWidthMobileXS) {
  .tabs {
    &__item {
      font-size: 12px;
    }
  }
}
