@import 'styles/default.scss';
@import 'styles/colors.scss';

.root {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 11px 10px 13px;
  box-sizing: border-box;
  transition: 0.3s;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  border-radius: $borderRadius;

  &_main {
    .caption {
      color: #fff;
    }

    &:hover {
      box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
    }
  }

  &_second {
    background-color: #eee;
    box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.15);

    .caption {
      color: $linkColor;
      margin-left: 25px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      background-color: $defaultBg;
    }

    &:hover {
      box-shadow: none;
      background-color: $defaultBg;

      .caption {
        color: #fff;
      }

      &:before {
        background-color: #fff;
      }
    }
  }

  &:hover {
    transition: 0.2s;

    .favoriteBtn {
      opacity: 1;
    }
  }

  &__dark#{&}_second {
    background: $darkBgContent;
    border-bottom: 1px solid $darkBorderColor;
    opacity: 1;

    .caption {
      color: $darkTextColor;
    }

    &::before {
      background: $darkBorderColor;
    }
  }

  &__dark#{&}_main {
    background: $darkBgContent !important;

    .caption {
      color: $darkTextColor;
    }
  }
}

.caption {
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.likeBtn,
.favoriteBtn {
  color: rgb(241, 241, 241);
  outline: none;
}

.likeBtn {
  transition: 0.3s;
  font-size: 18px;
  line-height: 1;

  &:hover {
    text-decoration: none;
  }

  &_active {
    transition: 0.3s;
  }
  .likeIcon {
    margin-left: 6px;
  }
}

.favoriteBtn {
  opacity: 0;
  transition: 0.3s;
  margin-left: auto;

  &_active {
    opacity: 1;
    transition: 0.3s;
  }
}

@media screen and (max-width: $siteWidth) {
  .favoriteBtn {
    opacity: 1;
  }
}

@media screen and (max-width: $siteWidthMobile) {
  .root {
    padding: 9px 10px 11px;

    &_item {
      width: 100%;
      height: 135px;
    }
  }

  .caption {
    width: 90%;
    font-size: 13px;
  }
}
