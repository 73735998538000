@import 'styles/default.scss';
@import 'styles/colors.scss';

.backplate {
  width: 100%;
  height: $headerHeight;
  box-sizing: border-box;
  background: rgb(80, 109, 200);
  background: $headerBg;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 10;

  &__dark {
    background: $darkBgHeader;
    @include themeSwitchTransition;

    .svg {
      display: block;
      color: $darkTextColor;
      @include themeSwitchTransition;
    }
  }
}

.root {
  width: $siteWidth;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 $sideSpace;
  box-sizing: border-box;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  outline: none;
}

.logoIcon {
  box-sizing: border-box;
  color: $mainText;
  transition: 0.5s ease-in-out;
}

.logo:hover .logoIcon {
  transform: rotate(360deg);
  transition: 0.5s ease-in-out;
}

.svg {
  display: block;
  color: $defaultBg;
}

.logoName {
  margin-left: 16px;
  font-size: 32px;
  font-weight: bold;
  color: $mainText;
  font-style: italic;
  letter-spacing: 1px;
}

.search {
  margin-left: 20px;
}

@media screen and (max-width: $siteWidthMobile) {
  .root {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 15;
  }
  .logo {
    display: none;
  }
  .search {
    margin-left: 0;
    margin-right: 50px;
  }
}
