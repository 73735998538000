@import 'styles/default.scss';

.googleAuth {
  display: flex;
  width: 300px;

  &__btn {
    width: 130px;
    height: auto;
    display: block;
    border: none;
    background: none;
    cursor: pointer;
    background-color: #df4a32;
    color: #fff;
    padding: 8px 35px 2px;
    border-radius: $borderRadius;

    &:hover {
      background-color: #d4412a;
    }
  }
}
