@import 'styles/colors.scss';
@import 'styles/default.scss';

.root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 80px;
  flex-grow: 1;
}

.sadSmile {
  color: $defaultBg;
  width: 250px;
  height: 250px;
  margin-right: 50px;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.errorCode,
.errorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorCode {
  font-size: 150px;
  font-weight: bold;
  margin-bottom: 100px;
  letter-spacing: 6px;
}

.errorMessage {
  font-size: 26px;
  margin-bottom: 70px;
  line-height: 1.3;
  text-align: center;
}

.button {
  margin: 0;
}

@media screen and (max-width: $siteWidthMobile) {
  .root {
    padding-bottom: 0;
  }
  .sadSmile {
    display: none;
  }
  .errorCode {
    font-size: 130px;
  }
}
