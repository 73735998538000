@import 'styles/default.scss';

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.5;
  margin: 20px 0;
}

.content {
  text-align: justify;
  line-height: 1.8;
  font-size: 16px;
}

.head1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
  margin: 25px 0 5px;
}

.link {
  font-size: 16px;
}

.listing {
  text-align: left;
}

@media screen and (max-width: $siteWidthMobile) {
  .title {
    font-size: 20px;
  }

  .content {
    font-size: 14px;
  }

  .link {
    font-size: 14px;
  }

  .head1 {
    font-size: 18px;
  }
}
