.formItem {
  min-width: 100%;
  margin: 0 12px 12px 0;
  box-sizing: border-box;
  overflow: hidden;
}

.fileInput {
  height: unset;
  padding: 8px 0 16px;
}
