@import 'styles/default.scss';

.title {
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: $fontMain;
}

@media screen and (max-width: $siteWidthMobile) {
  .title {
    font-size: 32px;
    margin-bottom: 18px;
  }
}
