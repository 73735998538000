@import 'styles/colors.scss';

.root {
  color: $defaultBg;

  &__dark {
    color: $darkTextColor;
  }
}

.small {
  font-size: 14px;
}

.middle {
  font-size: 16px;
}

.large {
  font-size: 18px;
}
