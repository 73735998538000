@import 'styles/default.scss';
@import 'styles/colors.scss';

.backplate {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  flex-grow: 1;
  position: relative;
  padding: 16px 0;

  &__dark {
    background: $darkBg;
    @include themeSwitchTransition;
  }
}

.root {
  width: $siteWidth;
  padding: 0 $sideSpace;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  min-height: 400px;
}

.addAuthor {
  position: absolute;
  top: 3px;
  right: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: $borderRadius;
  cursor: pointer;
  justify-self: center;
  box-sizing: border-box;
  transition: 0.1s;
  width: 35px;
  height: 35px;
  border: 1px solid $defaultBg;
  background-color: #eee;
  border-radius: $borderRadius;
  overflow: hidden;

  &:hover {
    transition: 0.1s;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $defaultBg;
    width: 55%;
    height: 2px;
    border-radius: $borderRadius;
  }

  &::after {
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

@media screen and (max-width: $siteWidth) {
  .root {
    width: 100%;
    padding: 10px $sideSpace_480;
  }
}
