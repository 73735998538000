@import 'styles/default.scss';

@media screen and (min-width: $siteWidth) {
  .root {
    &:hover {
      fill: #ffd000;
      stroke-width: 0.5;
    }
  }
}
