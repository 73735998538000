@import 'styles/colors.scss';
@import 'styles/default.scss';


.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #eee;
  padding: 50px 0;
  height: 250px;

  &__dark {
    background: $darkBgContent;
    @include themeSwitchTransition;
  }
}

.fileName,
.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
}

.download {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}

.downloadInfo {
  font-size: 18px;
  padding: 20px;
  text-align: center;
  line-height: 1.5;
}

.downloadButton {
  font-size: 28px;
  padding: 16px 28px 20px;
}

@media screen and (max-width: $siteWidthMobile) {
  .fileName,
  .notFound,
  .downloadButton {
    font-size: 20px;
  }
}

@media screen and (max-width: 320px) {
  .fileName,
  .notFound,
  .downloadButton {
    font-size: 16px;
  }
}
