@import 'styles/colors.scss';
@import 'styles/default.scss';

.root {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 25px;
  margin-bottom: 30px;

  &__dark {
    .item {
      background-color: #000;

      .name {
        background: $darkBgContent;
        @include themeSwitchTransition;
      }
    }
  }
}

.item {
  box-sizing: border-box;
  align-self: center;
  justify-self: center;
  background-color: #f4f5ff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  transition: 0.1s;
  border-radius: $borderRadius;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  overflow: hidden;

  .preview {
    width: 180px;
    height: 180px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .name {
    width: 100%;
    letter-spacing: 2px;
    font-weight: bold;
    font-family: $fontMain;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    word-break: break-all;
    text-align: center;
    background: #fff;
    padding: 7px 0;
    margin-top: auto;
    box-shadow: 0 -2px 10px 4px rgba(0, 0, 0, 0.2);
  }

  &:hover {
    transition: 0.2s;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: $siteWidthTab) {
  .root {
    grid-template-columns: repeat(2, 1fr);
  }

  .item {
    .preview {
      width: 40vw;
      height: 40vw;
    }

    .name {
      font-size: 2.5vw;
    }
  }
}

@media screen and (max-width: $siteWidthMobile) {
  .root {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 15px;
    grid-column-gap: 15px;
  }

  .item {
    .preview {
      width: 45vw;
      height: 45vw;
    }

    .name {
      font-size: 3vw;
    }
  }
}
