$siteWidth: 1024px;
$siteWidthTab: 768px;
$siteWidthMobile: 500px;
$siteWidthMobileXS: 360px;
$headerHeight: 80px;
$footerHeight: 100px;
$sideSpace: 24px;

$authorGridColCount: 4;

$borderRadius: 8px;
$fontMain: "Mulish";

// 480px size
$sideSpace_480: 18px;

@mixin themeSwitchTransition {
  transition: 0.25s all;
}
