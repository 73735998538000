@import 'styles/default.scss';

.title {
  margin: 30px 0;
  font-size: 24px;
  font-weight: bold;
}

.sheets {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin: 30px 0;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  font-size: 20px;
}

@media screen and (max-width: $siteWidthMobile) {
  .sheets {
    grid-template-columns: repeat(1, 1fr);
  }
}
