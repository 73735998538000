@import 'styles/colors.scss';
@import 'styles/default.scss';

.root {
  &__dark {
    .genres {
      &_item {
        color: $darkTextColor;
        background: #666;

        &:hover {
          color: $darkTextColor;
          background: unset;
        }

        &__selected {
          background: #222;

          &:hover {
            color: $darkTextColor;
            background: #222;
          }
        }
      }
    }
  }
}

.formItem {
  min-width: 100%;
  margin: 0 12px 12px 0;
  box-sizing: border-box;
  overflow: hidden;
}

.image {
  display: block;
  width: 150px;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.fileInput {
  height: unset;
  padding-bottom: 16px;
}

.genres {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dedede;
  margin-bottom: 8px;
  padding: 4px 0;
  font-size: 14px;

  &_item {
    padding: 3px 4px;
    box-sizing: border-box;
    border: 1px solid #222;
    color: #222;
    border-radius: $borderRadius;
    margin: 0 6px 6px 0;
    cursor: pointer;
    user-select: none;
    line-height: 1.3;
    flex-grow: 1;
    text-align: center;

    &:hover {
      background-color: #eee;
    }

    &__selected {
      color: #fff;
      background-color: #222;

      &:hover {
        background-color: #333;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .image {
    width: 100%;
  }
}
