@import 'styles/colors.scss';

.root {
  position: relative;
  display: flex;
  flex-direction: row;

  &__dark {
    .input {
      color: $darkBgInputText;
      border-color: $darkBorderColor;

      &::placeholder {
        color: $darkBgInputPlaceholder;
      }

      &.text {
        background: $darkBgInput;
      }

      &.file {
        background: transparent;
        color: $darkTextColor;
      }
    }

    .required::before {
      color: $darkTextColor;
    }
  }
}

.input {
  display: block;
  padding: 0 14px;
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid #dedede;
  background-color: #fff;
  font-size: 16px;
  color: #666;
  letter-spacing: 1px;
  outline: none;
  position: relative;
  box-sizing: border-box;

  &::placeholder {
    color: #999;
    font-size: 14px;
  }
}

.file {
  font-size: 14px;
}

.required {
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: relative;
    content: '*';
    color: $errorMessage;
    margin-right: 6px;
  }
}

.checkbox {
  font-size: 14px;
  padding: 0;
  margin: 0;
  height: 20px;
}
