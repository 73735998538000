@import 'styles/colors.scss';
@import 'styles/default.scss';

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.authorName {
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;
  letter-spacing: 1px;
  line-height: 1.3;
}

.actions {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: $defaultBg;
  border-radius: 16px;

  &__dark {
    background: $darkBgContent;
  }
}

.editBtn,
.likeBtn,
.favoriteBtn {
  color: rgb(241, 241, 241);
  padding: 0;
  margin: 0;
  min-width: unset;
  margin-right: 20px;
  outline: none;
}

.likeBtn {
  transition: 0.3s;
  font-size: 18px;
  line-height: 1;

  &:hover {
    text-decoration: none;
  }

  &_active {
    transition: 0.3s;
  }
  .likeIcon {
    margin-left: 6px;
  }
}

.favoriteBtn {
  opacity: 0;
  transition: 0.25s;
  margin-right: 0;

  &_showAlways {
    opacity: 1;
  }

  &_active {
    opacity: 1;
    transition: 0.25s;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;

  &__dark {
    .photo {
      background: transparent;
      box-shadow: none;
      border: 1px solid $darkBorderColor;
    }
  }
}

.description {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.photo {
  margin-right: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: $borderRadius;
  width: 400px;
  height: 400px;
  background-color: #cecece;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.authorInfo {
  display: block;
  width: 550px;
  line-height: 1.8;
  text-align: justify;
}

.sheets {
  display: flex;
  flex-direction: column;
}

.sheetTitle {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #ababab;
}

.notYetSheets {
  font-size: 18px;
  color: $errorMessage;
  margin-bottom: 20px;
}

.sheetItemAdd {
  width: fit-content;
}

.addSheetIcon {
  width: 18px;
  height: auto;
  transition: 0.2s;
}

.items {
  display: grid;
  grid-template-columns: repeat($authorGridColCount, 1fr);
  grid-row-gap: 20px;
  margin-bottom: 20px;
}

.genres {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  box-sizing: border-box;

  &_item {
    padding: 8px 12px;
    background-color: rgb(16, 104, 104);
    color: #fff;
    margin-right: 8px;
    margin-bottom: 6px;
    border-radius: $borderRadius;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: unset;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: rgb(9, 71, 71);
    }
  }
}

@media screen and (max-width: $siteWidth) {
  .favoriteBtn {
    opacity: 1;
  }
}

@media screen and (max-width: $siteWidthTab) {
  .photo {
    height: 300px;
  }
}

@media screen and (max-width: $siteWidthMobile) {
  .description {
    flex-direction: column;
  }
  .photo {
    width: 100%;
    height: auto;
  }
  .authorInfo {
    margin-top: 20px;
    width: 100%;
  }

  .editBtn {
    font-size: 0;
  }

  .title {
    flex-direction: column;
    align-items: flex-start;
  }

  .actions {
    align-self: flex-end;
  }
}
