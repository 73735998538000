@import 'styles/default.scss';
@import 'styles/colors.scss';

.authors {
  display: grid;
  grid-template-columns: repeat($authorGridColCount, 1fr);
  grid-row-gap: 30px;
  margin-bottom: 40px;
}

@media screen and (max-width: $siteWidthTab) {
  .authors {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 30px;
  }
}

@media screen and (max-width: $siteWidthMobile) {
  .authors {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}

@media screen and (max-width: $siteWidthMobileXS) {
  .authors {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;

    &__item {
      width: 100%;
      height: 250px;
    }
  }
}
