@import 'styles/colors.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20vh;
}

h1 {
  text-align: center;
}

.input {
  width: 300px;
  margin: 0 0 12px 0;
}

.error {
  color: $errorMessage;
  margin: 10px 0;
  width: 300px;

  ol {
    margin-left: 15px;
    padding: 0;

    li {
      margin: 10px 0;
    }
  }
}

.successChanePass {
  margin-top: 50px;
  font-size: 18px;
}

.link {
  font-size: 18px;
}