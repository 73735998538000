@import 'styles/colors.scss';

.root {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 15vh;
}

.successAuth {
  display: flex;
  flex-direction: column;
}

.successAuthMsg {
  text-align: center;
  line-height: 2;
}

.successAuthMsg,
.logoutBtn {
  font-size: 18px;
}

.linkWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .button {
    margin-right: 30px;
  }
}

.logoutBtn {
  margin-left: 20px;
  margin-top: 20px;
}
