@import 'styles/default.scss';
@import 'styles/colors.scss';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  box-sizing: border-box;
  background-size: cover;
  background-attachment: fixed;
  background: rgb(225, 232, 255);
  background: linear-gradient(135deg, rgb(249, 251, 255) 0%, rgba(255, 255, 255, 1) 100%);

  &__dark {
    background: $darkBg;
    @include themeSwitchTransition;
  }
}
