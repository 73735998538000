@import 'styles/default.scss';
@import 'styles/colors.scss';

.root {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 100px 2px rgba(0, 0, 0, 0.1);
  min-height: 100%;
  box-sizing: border-box;
}
