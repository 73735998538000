.root {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
  z-index: 10;
  width: 100%;
  height: 100%;
}
