@import 'styles/colors.scss';

.root {
  display: block;
  width: 300px;
}

.title {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 15px;
  text-align: center;
}

.input {
  width: 100%;
  margin: 0 0 12px 0;
}

.btnsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.errorsMsg {
  margin: 15px 0;
  color: $errorMessage;
  font-size: 14px;
  max-width: 300px;
}

.privacy {
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-left: 5px;
  box-sizing: border-box;
  cursor: pointer;

  .link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__checkbox {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-right: 10px;
    cursor: pointer;
  }
}
