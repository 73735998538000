@import 'styles/default.scss';
@import 'styles/colors.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  justify-self: center;
  box-sizing: border-box;
  transition: 0.1s;
  width: 200px;
  background-color: #fff;
  border-radius: $borderRadius;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  text-decoration: none;

  &:hover {
    transition: 0.2s;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.5);

    .favoriteBtn {
      opacity: 1;
    }
  }

  &__dark {
    box-shadow: none;
    background: $darkBg;

    .name {
      background: $darkBgContent;
    }

    .edit {
      background: $darkBgContent;

      &_icon_dot {
        background: $darkTextColor;
      }

      &:hover {
        background: $darkTextColor;
      }
    }
  }
}

.edit {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  z-index: 5;
  background-color: #bdc1d2;
  border-radius: 0 0 0 $borderRadius;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $defaultBg;
  }

  &:hover .edit_icon_dot {
    background: #bdc1d2;
  }

  &_icon {
    position: relative;
    box-sizing: border-box;
    width: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_dot {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background: $defaultBg;
    }
  }
}

.editMenu {
  position: absolute;
  right: 50px;
  top: 0px;
  color: #000;
  background-color: rgba($color: #fff, $alpha: 0.85);
  display: flex;
  flex-direction: column;
  z-index: 5;
  border-radius: 2px;
  box-shadow: 0 0 4px 2px rgba($color: #000000, $alpha: 0.3);
  user-select: none;
  border-radius: $borderRadius;
  overflow: hidden;

  &_item {
    padding: 8px 15px;
    text-align: right;

    &:hover {
      background-color: #ccc;
    }

    &:active {
      background-color: #aaa;
    }

    &__remove {
      color: $errorMessage;
    }
  }
}

.img {
  width: 100%;
  height: 160px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(10px) grayscale(50%);
    overflow: hidden;

    &__default {
      filter: unset;
    }
  }

  &_main {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }
}

.name {
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: $defaultBg;
  color: #fff;
  padding: 0 5px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  line-height: 1.2;
}

.favoriteBtn {
  opacity: 0;
  padding: 0 !important;
  margin: 0;
  position: absolute;
  z-index: 5;
  top: 10px;
  left: 10px;
  transition: 0.1s;
  min-width: unset !important;
  outline: none;

  &_active {
    opacity: 1;
    transition: 0.3s;
  }
}

@media screen and (max-width: $siteWidth) {
  .favoriteBtn {
    opacity: 1;
  }
}

@media screen and (max-width: $siteWidthTab) {
  .root {
    filter: unset;
  }
}

@media screen and (max-width: $siteWidthMobile) {
  .bottom {
    font-size: 12px;
    padding: 5px 5px 10px;
    line-height: 1.5;
  }

  .root {
    width: 160px;
    height: 180px;

    &_add {
      width: 150px;
      height: 150px;
    }
  }

  .top {
    &_index {
      font-size: 12px;
      width: 30px;
      padding: 5px 5px;
    }

    &_edit {
      padding: 0 6px 0;
    }
  }
}

@media screen and (max-width: 321px) {
  .root {
    &_add {
      width: 130px;
      height: 130px;
    }
  }
}
