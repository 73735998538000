@import 'styles/colors.scss';
@import 'styles/default.scss';

.root {
  &__dark {
    .searchTitle {
      background: $darkBgContent;
      @include themeSwitchTransition;
    }

    .closeIcon::after,
    .closeIcon::before {
      background-color: $darkTextColor;
      @include themeSwitchTransition;
    }

    .dropSearch {
      @media screen and (max-width: $siteWidthMobile) {
        background-color: $darkBgContent;
      }
    }
  }
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 35px;
}

.searchTitle {
  font-size: 18px;
  line-height: 1;
  margin-top: 10px;
  margin-bottom: 20px;
  background: $defaultBg;
  border-radius: $borderRadius;
  color: #fff;
  padding: 8px 10px 10px;
}

.dropSearch {
  display: block !important;
  position: absolute;
  top: 40px;
  right: 50px;
  cursor: pointer;

  @media screen and (max-width: $siteWidthMobile) {
    position: relative;
    width: 100%;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    display: block;
    background-color: $defaultBg;
    color: #fff;
    padding: 10px 5px;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }
}

.closeIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -25px;
  width: 17px;
  height: 17px;
}

.closeIcon::after,
.closeIcon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: $defaultBg;
}

.closeIcon::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.closeIcon::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.authorCard {
  margin-right: 20px;
  margin-bottom: 20px;
}

.authorCard:last-child {
  margin-right: 0;
}

.pagination {
  margin-bottom: 40px !important;
}

@media screen and (max-width: $siteWidthMobile) {
  .closeIcon {
    display: none;
  }

  .authorCard {
    width: 150px !important;
    height: 150px !important;
  }
}

@media screen and (max-width: 320px) {
  .authorCard {
    width: 130px !important;
    height: 130px !important;
  }
}
