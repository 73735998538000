@import 'styles/default.scss';
@import 'styles/colors.scss';

.title {
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 35px;
  line-height: 1.5;
}

.aboutUs {
  text-align: justify;
  text-indent: 25px;

  p,
  a {
    font-size: 18px;
    line-height: 1.8;
  }
}

.contacts {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  box-sizing: border-box;
}

.contact {
  display: flex;
  box-sizing: border-box;
  margin-bottom: 30px;
  margin-right: 50px;

  &:last-child {
    margin-right: 0;
  }

  .avatar {
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 150px;
    border: 1px solid #ccc;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    background: radial-gradient(circle, rgb(255 246 255) 0%, rgb(0 0 0) 100%);
    filter: grayscale(40%) brightness(1.2);
    position: relative;

    &:hover .bgBlur {
      filter: brightness(1);
      transition: 0.5s;
    }

    .img {
      display: block;
      width: 180px;
      height: 180px;
      box-sizing: border-box;
    }

    .bgBlur {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: brightness(0.8);
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.5s;
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;

    &__name {
      font-size: 18px;
      margin-bottom: 6px;
      letter-spacing: 1px;
    }

    &__role {
      font-size: 14px;
      margin-bottom: 16px;
    }

    &__socials {
      line-height: 1.5;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 10px;
      grid-auto-flow: row;
    }

    .webPage {
      margin-top: 10px;
    }
  }
}

.separator {
  display: block;
  height: 1px;
  background: rgb(183, 185, 202);
  margin: 40px 0;
}

@media screen and (max-width: $siteWidth) {
  .contacts {
    flex-direction: column;
  }
  .contact {
    margin-right: 0;
  }
}

@media screen and (max-width: $siteWidthMobile) {
  .title {
    margin-top: 20px;
  }

  .separator {
    margin: 25px 0;
  }

  .contacts {
    padding: 20px 0;
  }

  .contact {
    .avatar {
      .img {
        width: 150px;
        height: 150px;
      }
    }
    .infos {
      margin-left: 15px;

      &__name {
        font-size: 16px;
      }
      &__role {
        margin-bottom: 10px;
      }
      .webPage {
        margin-top: 6px;
      }
    }
  }
}

@media screen and (max-width: $siteWidthMobileXS) {
  .contact {
    .avatar {
      .img {
        width: 120px;
        height: 120px;
      }
    }
    .infos {
      margin-left: 10px;

      &__name {
        font-size: 14px;
        margin-bottom: 2px;
      }
      &__role {
        font-size: 12px;
      }
      .webPage {
        margin-top: 0;
      }
    }
  }
}
