@import 'styles/colors.scss';

.root {
  position: relative;
  display: inline-flex;
  flex-direction: row;

  &__dark {
    .link {
      color: $darkTextColor;

      &:active {
        color: $darkTextColor;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.link {
  color: $linkColor;
  justify-content: center;

  &:active {
    color: $linkColor;
  }

  &:hover {
    text-decoration: none;
  }
}

.xsmall {
  font-size: 12px;
}

.small {
  font-size: 14px;
}

.medium {
  font-size: 16px;
}

.large {
  font-size: 18px;
}
